let body = document.querySelector('body');
let burger = document.querySelector('.js-burger');
let menu = document.querySelector('.js-menu');
let closeBtn = document.querySelector('.js-close-btn');

burger.onclick = function() {
	body.classList.toggle('is-overflow');
	menu.classList.toggle('is-open');
}

closeBtn.onclick = function() {
	body.classList.toggle('is-overflow');
	menu.classList.toggle('is-open');
}
